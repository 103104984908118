import { log } from "shared-lib/helpers/logging/logger";

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_APP_ID ?? "";

export const pageview = () => {
  log("FB track event PageView >>> ", self.fbq?.getState());
  self.fbq?.("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  log(`FB track event ${name} >>> `, self.fbq?.getState(), options);
  self.fbq?.("track", name, options);
};
