"use client";
import { useEffect, Fragment } from "react";
import * as gtag from "shared-lib/gtag";
import { log } from "shared-lib/helpers/logging/logger";

import { usePathname } from "next/navigation";
import {
  TRACKING_CATEGORIES,
  TRACKING_EVENTS,
  logStatsig,
} from "shared-lib/helpers/statsigFunctions";

import { ClickServer } from "shared-lib/clickserver";

export default function TheClickServer() {
  const pathName = usePathname();

  useEffect(() => {
    while (!window.clickServer) {
      ClickServer.init();
      ClickServer.setIframeTracking((id) => {
        log({ id });
        if (id?.startsWith("twitter-widget-")) {
          // twitter
          log("social embed clicked");
          ClickServer.track(TRACKING_EVENTS.social_embed_click);
        } else if (id?.startsWith("widget")) {
          // youtube
          log("social embed clicked");
          ClickServer.track(TRACKING_EVENTS.social_embed_click);
        } else if (id?.startsWith("instagram-embed-")) {
          // instagram
          log("social embed clicked");
          ClickServer.track(TRACKING_EVENTS.social_embed_click);
        } else if (id?.startsWith("goog_")) {
          log("video iframe click");

          gtag.event({
            action: TRACKING_EVENTS.click_video_ad_bottom_right,
            category: TRACKING_CATEGORIES.interaction,
            label: id,
          });

          logStatsig(TRACKING_EVENTS.click_video_ad_bottom_right, {
            ad_slot: id,
          });

          ClickServer.track(TRACKING_EVENTS.click_video_ad_bottom_right, {
            ad_slot: id,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    ClickServer.pageView(pathName ?? "");
  }, [pathName]);

  return <Fragment key="click-server" />;
}
